import axiosClient from "./clients/axiosClient";
const resource = "/budgets";
const lgId = process.env.VUE_APP_LOCAL_GOV_ID;

export default {
  getBudget(params = "") {
    return axiosClient.get(`${resource}/${lgId}${params}`);
  },
  getBudgetDetails(year) {
    return axiosClient.get(`budget-details/${lgId}/${year}`);
  },
};
